<template>
  <div>
    <go-back title="Verification Details" />
    <spinner v-if="loading || !verification" />
    <b-card v-else>
      <b-row class="similarity-container">
        <b-col sm="6" md="5" class="left-image">
          <h2>{{ $t("verification.show.photo_a") }}</h2>
          <img
            :src="photoA"
            data-group="verification"
            style="aspect-ratio: 1/1; object-fit: cover; width: 100%"
          />
        </b-col>
        <b-col sm="12" md="2" class="similarity">
          <div>
            <div v-if="processing">
              <p class="text-center status-label">
                {{ $t("common.status.processing") }}...
              </p>
              <div
                class="inprogress-indicator"
                :title="$t('common.status.processing')"
              >
                <feather-icon
                  icon="CpuIcon"
                  size="92"
                  class="p-2 text-success"
                />
                <feather-icon
                  icon="UserIcon"
                  size="92"
                  class="p-2 text-warning"
                />
              </div>
            </div>
            <template v-else>
              <template v-if="verification.status == 'completed'">
                <p class="text-center status-label">Similarity</p>
                <div style="width: 110px" class="mx-auto">
                  <match-similarity :similarity="verification.similarity" />
                </div>
              </template>
              <span v-else>
                <p class="text-center">
                  {{ $t("verification.status." + verification.status) }}
                </p>
              </span>
            </template>
          </div>
        </b-col>

        <b-col sm="6" md="5" class="right-image">
          <h2>{{ $t("verification.show.photo_b") }}</h2>
          <img
            :src="photoB"
            data-group="verification"
            style="aspect-ratio: 1/1; object-fit: cover; width: 100%"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { show } from "@/api/verifications.api";
import Spinner from "@/components/Spinner.vue";
import MatchSimilarity from "../queries/components/MatchSimilarity.vue";
import GoBack from "@/components/Common/GoBack.vue";
export default {
  name: "QueryShow",
  components: { Spinner, MatchSimilarity, GoBack },
  data() {
    return {
      loading: false,
      verification: null,
      timer: null,
    };
  },
  watch: {
    verificationId() {
      this.fetch();
    },
  },
  computed: {
    verificationId() {
      return this.$route.params.id;
    },

    photoA() {
      return this.verification?.photos[0]?.media.url;
    },
    photoB() {
      return this.verification?.photos[1]?.media.url;
    },
    processing() {
      return ["pending", "processing"].includes(this.verification.status);
    },
  },
  methods: {
    async fetch() {
      if (this.loading) return;
      //TODO: catch
      this.loading = !this.verification;
      const { data } = await show(this.verificationId);
      if (!this.verification) {
        this.verification = data;

        this.loading = false;
      } else {
        this.verification.status = data.status;
        this.verification.similarity = data.similarity;
      }

      if (this.processing) {
        this.timer = setTimeout(() => {
          this.fetch();
        }, 1000);
      }
    },
  },
  mounted() {
    this.fetch();
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";

img {
  max-width: 100%;
}

.inprogress-indicator {
  height: 92px !important;

  & > svg {
    position: absolute;
    width: 100%;
    left: 0;
    animation-direction: alternate;

    &:first-of-type {
      animation: fadeIn 2s ease-out infinite;
      animation-direction: alternate;
    }

    &:last-of-type {
      animation: fadeIn 2s ease-out infinite;
      animation-direction: alternate-reverse;
    }
  }
}
@keyframes fadeIn {
  0% {
    display: none;
    opacity: 1;
  }

  40% {
    display: block;
    opacity: 1;
  }

  60% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    display: none;
    opacity: 0;
  }

  40% {
    display: block;
    opacity: 0;
  }

  60% {
    display: block;
    opacity: 1;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.similarity > div {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.left-image,
.right-image {
  width: 50%;
}

.left-image > img,
.right-image > img {
  border-radius: 0.5rem;
}

.status-label {
  font-size: 1.4rem;
  font-weight: semibold;
}

@media (max-width: (map-get($grid-breakpoints, md))) {
  .similarity {
    order: 1;
    margin-bottom: 2rem;
  }

  .left-image {
    order: 2;
  }

  .right-image {
    order: 3;
  }
}
</style>